import React, { useState, useRef, useEffect } from 'react';

const OTPInput = ({ 
  className = '', 
  title = 'Enter the 6-digit code', 
  slug = 'otp', 
  locked = false, 
  errorText = null, 
  helpText = '', 
  value = '', 
  length = 6, 
  onChange, 
  ...rest 
}) => {
  const [values, setValues] = useState(Array(length).fill(''));
  const [isLocked, setIsLocked] = useState(locked);
  const [error, setError] = useState(errorText);
  const inputsRef = useRef([]);

  useEffect(() => {
    if (value) {
      const newValues = value.split('').slice(0, length);
      setValues([...newValues, ...Array(length - newValues.length).fill('')]);
    }
  }, [value, length]);

  useEffect(() => {
    setIsLocked(locked);
  }, [locked]);

  useEffect(() => {
    setError(errorText);
  }, [errorText]);

  const handleChange = (e, index) => {
    let { value } = e.target;
    if (value.match(/^[a-zA-Z0-9]$/) || value === '') {
      value = value.toUpperCase();
      const newValues = [...values];
      newValues[index] = value;
      setValues(newValues);
      if (value !== '' && index < length - 1) {
        inputsRef.current[index + 1].focus();
      }
      if (onChange) {
        onChange(newValues.join(''));
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      if (values[index] === '') {
        if (index > 0) {
          inputsRef.current[index - 1].focus();
        }
      } else {
        const newValues = [...values];
        newValues[index] = '';
        setValues(newValues);
        if (onChange) {
          onChange(newValues.join(''));
        }
      }
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('text');
    if (pasteData.match(/^[a-zA-Z0-9]+$/)) {
      const newValues = pasteData.slice(0, length).toUpperCase().split('');
      setValues(newValues);
      newValues.forEach((value, i) => {
        if (inputsRef.current[i]) {
          inputsRef.current[i].value = value;
        }
      });
      if (onChange) {
        onChange(newValues.join(''));
      }
      e.preventDefault();
    }
  };

  return (
    <div className={`form-group ${className} ${error ? '--has-error' : ''}`}>
      <label htmlFor={slug}>{title}</label>
      <div className="otp-inputs" id={slug} onPaste={handlePaste}>
        {values.map((value, index) => (
          <input
            key={index}
            type="text"
            maxLength="1"
            value={value}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            ref={(el) => (inputsRef.current[index] = el)}
            disabled={isLocked}
            className="form-input"
            {...rest}
          />
        ))}
      </div>
      {helpText && <div className='help-text'>{helpText}</div>}
      {error && <div className='error-text'>{error}</div>}
    </div>
  );
};

export default OTPInput;
