import React, { useState, useEffect } from 'react';
import * as SVG from '../SVGs';

function ListItem({ API_URL, item, listColour, setModal, closeModal, status }) {
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [itemName, setItemName] = useState('');
    const [itemDescription, setItemDescription] = useState('');

    useEffect(() => {
        setItemName(item.name);
        setItemDescription(item.notes);
        setChecked(item.is_checked);
        if(item.is_checked == 0){
            setChecked(false);
        }else{
            setChecked(true);
        }
    }, [item]);

    const handleToggle = async () => {
        if(status != 'connected'){
            //alert('You are offline. Please connect to the internet to update your list.');
            return;
        }
        if (checked === false) {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/items/${item.item_uid}/check?token=${localStorage.getItem('user_token')}`, {
                    method: 'PUT',
                });
        
                if (response.ok) {
                    const data = await response.json();
                    if (data.status === 'success') {
                        setChecked(true);
                    }
                }
            } catch (error) {
                console.error('Fetch error:', error);
            } finally {
                setLoading(false);
            }
        }else if (checked === true) {
            setLoading(true);
            try {
                const response = await fetch(`${API_URL}/items/${item.item_uid}/uncheck?token=${localStorage.getItem('user_token')}`, {
                    method: 'PUT',
                });
        
                if (response.ok) {
                    const data = await response.json();
                    if (data.status === 'success') {
                        setChecked(false);
                    }
                }
            } catch (error) {
                console.error('Fetch error:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleEditItem = () => {
        setModal({type: 'edit-item', item, listColour});
    };

    return (
        <div className={`list-item ${checked === true ? '--active' : ''} ${loading === true ? '--loading' : ''} ${status != 'connected' ? '--disabled' : ''}`}>
            <button className='list-item-checkbox' onClick={handleToggle}>
                <div className='circle'></div>
                <div className='spinner'>
                    <SVG.Spinner />
                </div>
            </button>
            <button className="list-item-body" onClick={handleEditItem}>
                <span className='name'>{itemName}</span>
                {itemDescription && <span className='description'>{itemDescription}</span>}
            </button>
        </div>
    );
}

export default ListItem;
