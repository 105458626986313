import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as SVG from './SVGs';

import AddItem from './components/AddItem';
import ListItem from './components/ListItem';

function List({ API_URL, updateLists, setLists, status, lists, setModal, closeModal }) {
    const { uid } = useParams();
    const [listUid, setListUid] = useState('');
    const [listTitle, setListTitle] = useState('');
    const [listUserName, setListUserName] = useState('');
    const [listColour, setListColour] = useState('');
    const [listIcon, setListIcon] = useState('');
    const [listItems, setListItems] = useState([]);

    const [contextMenuActive, setContextMenuActive] = useState(false);
    const contextMenuRef = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        const activeList = lists.find(list => list.list_uid === uid);
        if (activeList) {
            setListUid(activeList.list_uid);
            setListTitle(activeList.list_name);
            setListUserName(activeList.user_name);
            setListColour(activeList.list_colour);
            setListIcon(activeList.list_icon);
            setListItems(activeList.items);
        } else {
            // Clear localstorage activeListUid
            localStorage.removeItem('activeListUid');
            navigate('/'); // Redirect to not-found route if uid is invalid
        }
    }, [uid, lists, navigate]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
                setContextMenuActive(false);
                console.log('context menu closed');
            }
        };

        if (contextMenuActive) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [contextMenuActive]);

    const IconComponent = SVG[listIcon];

    const handleGoBack = () => {
        localStorage.removeItem('activeListUid');
        navigate('/');
    }

    const handleOpenContextMenu = (event) => {
        event.stopPropagation(); // Prevent the click event from bubbling up and being caught by the document
        setContextMenuActive(true);
    }
    const handleCloseContextMenu = (event) => {
        event.stopPropagation(); // Prevent the click event from bubbling up and being caught by the document
        setContextMenuActive(false);
    }

    const handleEditList = () => {
        setContextMenuActive(false);

        if(status === 'offline') {
            setModal({type: 'offline-notice'});
        } else {
            setModal({type: 'edit-list', uid, listTitle, listUserName, listColour, listIcon});
        }
    };
    const handleShareList = () => {
        setContextMenuActive(false);
    
        if(status === 'offline') {
            setModal({type: 'offline-notice'});
        } else {
            setModal({type: 'share-list', uid, listColour});
        }
    };
    const handleLeaveList = () => {
        setContextMenuActive(false);
        if(status === 'offline') {
            setModal({type: 'offline-notice'});
        } else {
            setModal({type: 'leave-list', uid, listTitle, listColour, listIcon});
        }
    };
    const handleCleanUpList = async () => {
        setContextMenuActive(false);
        if(status === 'offline') {
            setModal({type: 'offline-notice'});
        } else {
            try {
                const response = await fetch(`${API_URL}/lists/${listUid}/cleanup?token=${localStorage.getItem('user_token')}`, {
                    method: 'PUT',
                });
        
                if (response.ok) {
                    const data = await response.json();
                    if (data.status === 'success') {
                        updateLists(data.lists);
                    }
                }
            } catch (error) {
                console.error('Fetch error:', error);
            }
        }
    };

    return (
        <main className={`${status !== 'connected' ? ' --has-status' : ''}`}>
            <div className='header'>
                {(status === 'offline' || status === 'connecting') && (
                    <div className='header-status'>
                        {status === 'offline' && (
                            <>
                                <div className='icon --error'><SVG.NetworkError /></div>
                                <span>Offline</span>
                            </>
                        )}
                        {status === 'connecting' && (
                            <>
                                <div className='icon --connecting'><SVG.Spinner /></div>
                                <span>Connecting</span>
                            </>
                        )}
                    </div>
                )}
                <div className='header-container'>
                    <button className='header-button back-button' onClick={handleGoBack}><div className='icon'><SVG.ArrowLeft /></div></button>
                    <div className={`title --${listColour}`}>
                        <div className='icon'>
                            {IconComponent && React.createElement(IconComponent)}
                        </div>
                        <span>{listTitle}</span>
                    </div>
                    <button className='header-button' onClick={handleOpenContextMenu}><div className='icon'><SVG.Ellipsis /></div></button>
                    <div className={`context-menu ${contextMenuActive ? '--active' : ''}`} ref={contextMenuRef}>
                        <button className='close-button' onClick={handleCloseContextMenu}><div className='icon'><SVG.Xmark /></div></button>
                        <div className='context-menu-container'>
                            <button onClick={handleCleanUpList} className='context-menu-item'><div className='item-icon'><SVG.Broom /></div><span>Clean up</span></button>
                            <button onClick={handleEditList} className='context-menu-item'><div className='item-icon'><SVG.EditSquare /></div><span>Edit List</span></button>
                            <button onClick={handleShareList} className='context-menu-item'><div className='item-icon'><SVG.UserPlus /></div><span>Share List</span></button>
                            <div className='context-menu-divider'></div>
                            <button onClick={handleLeaveList} className='context-menu-item --danger'><div className='item-icon'><SVG.ArrowRightFromBracket /></div><span>Leave List</span></button>
                        </div>
                    </div>
                </div>
            </div>
            <AddItem listUid={listUid} listColour={listColour} API_URL={API_URL} setLists={setLists} status={status} />
            <div className={`list-items --${listColour}`}>
                {listItems.map((item, index) => (
                    <ListItem API_URL={API_URL} key={item.item_uid} item={item} setModal={setModal} closeModal={closeModal} listColour={listColour} status={status} />
                ))}

                {listItems.length === 0 && (
                    <div className='no-items-message'>There's no items in this list yet.</div>
                )}
            </div>
        </main>
    );
}

export default List;
