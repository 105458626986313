import React, { useState, useEffect } from 'react';
import * as SVG from '../../SVGs';
import * as Button from '../Buttons';
import * as Inputs from '../Forms/Inputs';
import Spacer from '../Spacer';

function ModalSignIn({ API_URL, modalData, setModal, closeModal }) {
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState('');

    const handleClose = () => {
        document.querySelector('.modal').classList.remove('--active');
        document.querySelector('.modal-overlay').classList.remove('--active');
        setTimeout(() => {
            closeModal();
        }, 300);
        
    }

    const handleDeviceSignIn = (e) => {
        setModal({type: 'sign-in-code', referer: 'device', handleSignIn: modalData.handleSignIn});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setErrorText(''); // Clear any previous error messages
        
        const formData = new FormData(document.getElementById('sign-in-email-form'));
        console.log(formData);
    
        try {
            const response = await fetch(`${API_URL}/sign_in_code`, {
                method: 'POST',
                body: formData,
            });
    
            if (response.ok) {
                const data = await response.json();
                console.log('Response data:', data);
                if (data.status === 'success') {
                    setLoading(false);
                    setModal({type: 'sign-in-code', handleSignIn: modalData.handleSignIn});
                } else if (data.status === 'error') {
                    console.log('Error message from response:', data.message);
                    setErrorText(data.message);
                    setLoading(false);
                }
            } else {
                console.log('Response not OK:', response.statusText);
                setErrorText('An unexpected error occurred. Please try again.');
                setLoading(false);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            //setErrorText('Network error. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <>
            <p className='--large --center'>Enter your email address, we'll send you a one-time code.</p>
            <Spacer line={false} size='small' />
            <form id='sign-in-email-form' onSubmit={handleSubmit} className={`form`}>
                <Inputs.Text title='Email Address' slug='email' locked={loading} errorText={errorText} />
                <div className='form-group'>
                    <div className='button-group --fw --stack'>
                        <Button.Button type='submit' className={`--primary`} locked={loading} loading={loading}><span>Continue</span><SVG.ArrowRight /></Button.Button>
                    </div>
                </div>
            </form>
            <Spacer line={true} text='Or' size='' />
            <div className='button-group --fw --stack'>
                <Button.Button type='button' className={``} locked={loading} onClick={handleDeviceSignIn}><span>Sign in with Another Device</span></Button.Button>
            </div>
            <Spacer line={false} size='small' />
        </>
    );
}

export default ModalSignIn;
