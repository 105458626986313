import React from 'react';
import * as SVG from '../SVGs';

function Spacer({ line = false, icon = null, text = null, size = 'default' }) {
    const spacerClass = `spacer --${size} ${text ? '--has-text' : ''}`;
    
    return (
        <div className={spacerClass}>
            {line && <div className='line'></div>}
            {icon && <div className='icon'>{SVG[icon] ? React.createElement(SVG[icon]) : null}</div>}
            {text && <div className='text'>{text}</div>}
            {line && <div className='line'></div>}
        </div>
    );
}

export default Spacer;
