import { useState, useEffect } from 'react';

function Text({ 
    className = '', 
    title = 'Title', 
    slug = 'title', 
    locked = false, 
    errorText = null,
    helpText = '', 
    value = '', 
    onChange, 
    ...rest 
}) {
    const [inputValue, setInputValue] = useState(value);
    const [isLocked, setIsLocked] = useState(locked);
    const [error, setError] = useState(errorText);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    useEffect(() => {
        setIsLocked(locked);
    }, [locked]);

    useEffect(() => {
        setError(errorText);
    }, [errorText]);

    const handleChange = (e) => {
        setInputValue(e.target.value);
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <div className={`form-group ${className} ${error ? '--has-error' : ''}`}>
            <label htmlFor={slug}>{title}</label>
            <input 
                className={`form-input`} 
                type='text' 
                id={slug}
                name={slug}
                value={inputValue}
                onChange={handleChange}
                disabled={isLocked} 
                {...rest}
            />
            {helpText && <div className='help-text'>{helpText}</div>}
            {error && <div className='error-text'>{error}</div>}
        </div>
    );
}

export default Text;