import React, { useState, useEffect } from 'react';
import * as SVG from '../../SVGs';
import * as Button from '../Buttons';
import * as Inputs from '../Forms/Inputs';
import Spacer from '../Spacer';

function ModalEditItem({ API_URL, updateLists, modalData, setModal, closeModal }) {
    const [item, setItem] = useState(modalData.item);

    const [deleteLoading, setDeleteLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLocked, setDeleteLocked] = useState(false);
    const [saveLocked, setSaveLocked] = useState(true); // Initially lock save button
    const [formLocked, setFormLocked] = useState(false);

    useEffect(() => {
        // Set initial item when modalData changes
        setItem(modalData.item);
    }, [modalData]);

    useEffect(() => {
        // Check if any changes have been made to enable/disable save button
        const isItemChanged = JSON.stringify(item) !== JSON.stringify(modalData.item);
        setSaveLocked(!isItemChanged);
    }, [item, modalData.item]);

    const handleDelete = async () => {
        setDeleteLoading(true);
        setDeleteLocked(true);
        setFormLocked(true);

        try {
            const response = await fetch(`${API_URL}/items/${item.item_uid}?token=${localStorage.getItem('user_token')}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                const data = await response.json();
                if (data.status === 'success') {
                    updateLists(data.lists);
                    closeModal();
                }
            }
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            setDeleteLoading(false);
            setDeleteLocked(false);
            setFormLocked(false);
        }
    };

    const handleInputChange = (slug, value) => {
        setItem({
            ...item,
            [slug]: value,
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setSaveLoading(true);
        setSaveLocked(true);
        setFormLocked(true);

        try {
            // Perform save logic here (e.g., update API call)
            const response = await fetch(`${API_URL}/items/${item.item_uid}?token=${localStorage.getItem('user_token')}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(item),
            });

            if (response.ok) {
                const data = await response.json();
                if (data.status === 'success') {
                    updateLists(data.lists);
                    closeModal();
                }
            }
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            setSaveLoading(false);
            setSaveLocked(false);
            setFormLocked(false);
        }
    };

    return (
        <>
            <form className={`form --${modalData.listColour}`} onSubmit={handleFormSubmit}>
                <Inputs.Text
                    title='Name'
                    slug='name'
                    value={item.name}
                    helpText="What's the name of the item? Keep it short and simple!"
                    errorText=''
                    locked={formLocked}
                    onChange={(e) => handleInputChange('name', e.target.value)}
                />
                <Inputs.TextArea
                    title='Notes'
                    slug='notes'
                    value={item.notes}
                    rows='3'
                    helpText="Provide any additional details or notes about the item, such as brand, quantity, or special instructions."
                    errorText=''
                    locked={formLocked}
                    onChange={(e) => handleInputChange('notes', e.target.value)}
                />

                <Spacer size='extra-small' />
                <div className='form-group'>
                    <div className='button-group --fw --stack'>
                        <Button.Button type='submit' className={`--primary --${modalData.listColour}`} locked={saveLocked} loading={saveLoading}><span>Save Changes</span></Button.Button>
                        <Button.Text type='button' className='--danger' locked={deleteLocked} loading={deleteLoading} onClick={handleDelete}><SVG.TrashCan /><span>Delete Item</span></Button.Text>
                    </div>
                </div>
            </form>
        </>
    );
}

export default ModalEditItem;
