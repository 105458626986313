import React, { useState } from 'react';
import * as SVG from '../../SVGs';
import * as Button from '../Buttons';
import Spacer from '../Spacer';

function ModalManageAccount({ API_URL, modalData, setModal, closeModal }) {
    const [loading, setLoading] = useState(false);
    const [locked , setLocked] = useState(false);
    const [shareCode, setShareCode] = useState(null);

    const handleSignOut = () => {
        modalData.handleSignOut();
    }

    const handleCreateCode = async () => {
        setLocked(true);
        setLoading(true);
        try {
            const response = await fetch(`${API_URL}/sign_in_code/${localStorage.getItem('user_token')}`, {
                method: 'GET',
            });
    
            if (response.ok) {
                const data = await response.json();
                if (data.status === 'success') {
                    setShareCode(data.sign_in_code);
                    setLoading(false);
                }
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    return (
        <>
            <p className=''>Use the button below to generate a <strong>Sign-in Code</strong>. You can use this to sign in on another device.</p>
            <div className='button-group --fw'>
                <Button.Button className={`--primary --${modalData['listColour']}`} onClick={handleCreateCode} locked={locked} loading={loading}><span>Create Sign-in Code</span></Button.Button>
            </div>
            <Spacer size='extra-small' />
            <div className='share-code'>
                {shareCode && shareCode.split('').map((char, index) => (
                    <span key={index}>{char}</span>
                ))}
            </div>
            <Spacer line={true} />
            <div className='button-group --fw'>
                <Button.Button onClick={handleSignOut}><span>Sign Out</span></Button.Button>
            </div>
        </>
    );
}

export default ModalManageAccount;
