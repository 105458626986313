import React, { useState, useEffect } from 'react';
import * as SVG from '../SVGs';

import * as Modals from './Modals';

function Modal(props) {
    const [modalData, setModalData] = useState(null);
    const [closable, setClosable] = useState(false);
    const [title, setTitle] = useState(null);
    const [titleCentered, setTitleCentered] = useState(false);
    const [icon, setIcon] = useState(null);
    const [body, setBody] = useState(null);

    const handleClose = () => {
        //if closable is true, close the modal
        if (closable) {
            document.querySelector('.modal').classList.remove('--active');
            document.querySelector('.modal-overlay').classList.remove('--active');
            setTimeout(() => {
                props.closeModal();
            }, 300);
        }
        
    }

    useEffect(() => {
        // Update modalData state with the new props.modalData
        setModalData(props.modalData);
    }, [props.modalData]); // Ensure the effect runs whenever props.modalData changes
    
    useEffect(() => {
        if (modalData && modalData['type'] === 'add-list') {
            setTitle('Add a List');
            setIcon(null);
            setClosable(true);
            setBody(<Modals.AddList API_URL={props.API_URL} updateLists={props.updateLists} modalData={props.modalData} setModal={props.setModal} closeModal={props.closeModal}/>);
        }else if (modalData && modalData['type'] === 'new-list') {
            setTitle('Create a New List');
            setIcon(null);
            setClosable(true);
            setBody(<Modals.NewList API_URL={props.API_URL} updateLists={props.updateLists} modalData={props.modalData} setModal={props.setModal} closeModal={props.closeModal}/>);
        }else if (modalData && modalData['type'] === 'join-list') {
            setTitle('Join List');
            setIcon(null);
            setClosable(true);
            setBody(<Modals.JoinList API_URL={props.API_URL} updateLists={props.updateLists} modalData={props.modalData} setModal={props.setModal} closeModal={props.closeModal}/>);
        }else if (modalData && modalData['type'] === 'edit-list') {
            setTitle('Edit List');
            setIcon(null);
            setClosable(true);
            setBody(<Modals.EditList API_URL={props.API_URL} updateLists={props.updateLists} modalData={props.modalData} setModal={props.setModal} closeModal={props.closeModal}/>);
        }else if (modalData && modalData['type'] === 'share-list') {
            setTitle('Share List');
            setIcon(null);
            setClosable(true);
            setBody(<Modals.ShareList API_URL={props.API_URL} updateLists={props.updateLists} modalData={props.modalData} setModal={props.setModal} closeModal={props.closeModal}/>);
        }else if (modalData && modalData['type'] === 'leave-list') {
            setTitle('Leave List');
            setIcon(null);
            setClosable(true);
            setBody(<Modals.LeaveList API_URL={props.API_URL} updateLists={props.updateLists} modalData={props.modalData} setModal={props.setModal} closeModal={props.closeModal}/>);
        }else if (modalData && modalData['type'] === 'edit-item') {
            setTitle('Edit Item');
            setIcon(null);
            setClosable(true);
            setBody(<Modals.EditItem API_URL={props.API_URL} updateLists={props.updateLists} modalData={props.modalData} setModal={props.setModal} closeModal={props.closeModal}/>);
        }else if (modalData && modalData['type'] === 'offline-notice') {
            setTitle("You're Offline");
            setIcon('NetworkError');
            setClosable(true);
            setBody(<Modals.OfflineNotice API_URL={props.API_URL} updateLists={props.updateLists} modalData={props.modalData} setModal={props.setModal} closeModal={props.closeModal}/>);
        }else if (modalData && modalData['type'] === 'sign-in') {
            setTitle('Sign in to continue');
            setIcon(null);
            setClosable(false);
            setTitleCentered(true);
            setBody(<Modals.SignIn API_URL={props.API_URL} updateLists={props.updateLists} modalData={props.modalData} setModal={props.setModal} closeModal={props.closeModal}/>);
        }else if (modalData && modalData['type'] === 'sign-in-code') {
            setTitle('Enter your Sign-In Code');
            setIcon(null);
            setClosable(false);
            setTitleCentered(false);
            setBody(<Modals.SignInCode API_URL={props.API_URL} updateLists={props.updateLists} modalData={props.modalData} setModal={props.setModal} closeModal={props.closeModal}/>);
        }else if (modalData && modalData['type'] === 'manage-account') {
            setTitle('Manage Account');
            setIcon('User');
            setClosable(true);
            setBody(<Modals.ManageAccount API_URL={props.API_URL} updateLists={props.updateLists} modalData={props.modalData} setModal={props.setModal} closeModal={props.closeModal}/>);
        }

        //after a tiny delay, add the --active class to the modal
        setTimeout(() => {
            document.querySelector('.modal').classList.add('--active');
            document.querySelector('.modal-overlay').classList.add('--active');
        }, 10);

    }, [modalData]); // This effect depends on modalData

    return(
        <>
        <div className='modal-overlay' onClick={handleClose}></div>
        <div className='modal-container'>
            <div className={`modal ${closable ? '--has-close' : ''}`}>
                <div className='modal-header'>
                    <div className={`modal-title ${titleCentered ? '--centered':''}`}>
                        {icon && React.createElement(SVG[icon])}
                        <h2>{title}</h2>
                    </div>
                    <button type="button" className="modal-close" onClick={handleClose}><div className='icon'><SVG.Xmark /></div></button>
                </div>
                <div className='modal-body'>
                    {body}
                </div>
            </div>
        </div>
        </>
    );
}

export default Modal;