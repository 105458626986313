import { useState } from 'react';
import * as SVG from '../SVGs';

function AddItem(props) {
    const [loading, setLoading] = useState(false);
    const [itemName, setItemName] = useState('');
    const [itemDescription, setItemDescription] = useState('');

    let buttonState = '';
    if (loading === 1) {
        buttonState = ' --loading';
    } else {
        buttonState = '';
    }

    const handleInput = () => {
        const addItem = document.querySelector('.add-item-container');
        if (itemName !== '' || itemDescription !== '') {
            addItem.classList.add('--active');
        } else {
            addItem.classList.remove('--active');
        }
    };

    const handleItemNameChange = (event) => {
        setItemName(event.target.value);
        handleInput();
    };

    const handleItemDescriptionChange = (event) => {
        setItemDescription(event.target.value);
        handleInput();
    };

    const handleCancel = () => {
        if (!loading) {
            const addItem = document.querySelector('.add-item-container');
            addItem.classList.remove('--active');

            // Set itemName and itemDescription to empty
            setItemName('');
            setItemDescription('');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if(props.status !== 'connected'){
            return;
        }

        setLoading(true);
    
        const formData = new FormData(document.getElementById('new-item-form'));
        console.log(formData);
    
        try {
            const response = await fetch(`${props.API_URL}/items?token=${localStorage.getItem('user_token')}`, {
                method: 'POST',
                body: formData,
            });
    
            if (response.ok) {
                const data = await response.json();
                if (data.status === 'success') {
                    const parsedLists = data.lists; 
                    props.setLists(Object.values(parsedLists)); 
                    localStorage.setItem('lists', JSON.stringify(Object.values(parsedLists)));
    
                    setLoading(false);
                    handleCancel();
                } else {
                    setLoading(false);
                    console.error('Response status not success:', data);
                }
            } else {
                setLoading(false);
                console.error('Response not ok:', response.statusText);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={`add-item-container --${props.listColour} ${props.status != 'connected' ? '--disabled' : ''}`}>
            <form id='new-item-form' className="add-item" onSubmit={handleSubmit}>
                <input type="hidden" name="list_uid" value={props.listUid} />
                <button type="button" className="add-item-cancel" onClick={handleCancel}>
                    <div className='icon'><SVG.Xmark /></div>
                </button>
                <div className="item-name-container">
                    <input
                        className="item-name"
                        name="item_name"
                        type="text"
                        placeholder="Add an item..."
                        value={itemName}
                        onChange={handleItemNameChange}
                        onKeyDown={handleInput}
                        disabled={loading}
                    />
                </div>
                <div className="item-description-container">
                    <textarea
                        className="item-description"
                        name="item_notes"
                        rows="4"
                        placeholder="Describe the item..."
                        value={itemDescription}
                        onChange={handleItemDescriptionChange}
                        onKeyDown={handleInput}
                        disabled={loading}
                    ></textarea>
                </div>
                <div className="button-container">
                    <button type='submit' className={`add-item-button ${loading === true ? '--loading' : ''}`} disabled={loading}>
                        <span>Add Item</span>
                        <div className='button-loader'><SVG.Spinner /></div>
                    </button>
                </div>
            </form>
        </div>
    );
}

export default AddItem;
