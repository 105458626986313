import React, { useState } from 'react';
import * as SVG from '../../SVGs';
import * as Button from '../Buttons';
import Spacer from '../Spacer';

function ModalLeaveList({ API_URL, updateLists, modalData, setModal, closeModal }) {
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        document.querySelector('.modal').classList.remove('--active');
        document.querySelector('.modal-overlay').classList.remove('--active');
        setTimeout(() => {
            closeModal();
        }, 300);
        
    }

    const handleLeaveList = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_URL}/lists/${modalData['uid']}?token=${localStorage.getItem('user_token')}`, {
                method: 'DELETE',
            });
    
            if (response.ok) {
                const data = await response.json();
                if (data.status === 'success') {
                    updateLists(data.lists);
                    closeModal();
                }
            }
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <p className='--center'>Are you sure you want to leave this List?</p>
            <Spacer line={false} size='small' />
            <div className='button-group --fw'>
                <Button.Button onClick={handleClose} locked={loading}><span>Cancel</span></Button.Button>
                <Button.Button className={`--danger`} onClick={handleLeaveList} locked={loading} loading={loading}><SVG.ArrowRightFromBracket /><span>Leave List</span></Button.Button>
            </div>
        </>
    );
}

export default ModalLeaveList;
