import React, { useState } from 'react';
import * as SVG from '../../SVGs';
import * as Button from '../Buttons';
import Spacer from '../Spacer';

function ModalOfflineNotice({ modalData, setModal, closeModal }) {
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        document.querySelector('.modal').classList.remove('--active');
        document.querySelector('.modal-overlay').classList.remove('--active');
        setTimeout(() => {
            closeModal();
        }, 300);
        
    }

    return (
        <>
            <p className='--center --large'>You're currently offline, so this action can't be performed.</p>
            <p className='--center'>Please try again later!</p>
            <Spacer line={false} size='small' />
            <div className='button-group --fw'>
                <Button.Button onClick={handleClose}><span>Okay</span></Button.Button>
            </div>
        </>
    );
}

export default ModalOfflineNotice;
