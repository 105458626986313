import React, { useState, useEffect } from 'react';
import * as SVG from '../SVGs';

function ListMenuItem({ list, isActive, onClick }) {
    return (
        <button className={`list-menu-item --${list.list_colour} ${isActive ? '--active' : ''}`} onClick={onClick}>
            <div className='icon'>
                {React.createElement(SVG[list.list_icon])}
            </div>
            <span className='name'>{list.list_name}</span>
            <div className='count'><span>{list.item_count}</span></div>
        </button>
    );
}

export default ListMenuItem;