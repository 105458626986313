import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from './logo.js';
import * as SVG from './SVGs';
import * as Button from './components/Buttons';
import List from './List.js';
import ListMenuItem from './components/ListMenuItem.js';
import Modal from './components/Modal.js';

function App() {
    const API_URL = window.location.hostname === 'localhost' ? 'http://localhost:8010/proxy' : 'https://shoppinglist-api.dandyliontech.uk';

    const [loading, setLoading] = useState(true);
    const [loggedIn, setLoggedIn] = useState(null);
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [status, setStatus] = useState('connecting');
    const [lists, setLists] = useState([]);
    const [activeListUid, setActiveListUid] = useState(null);
    const [modalData, setModalData] = useState(null);
    const [modalActive, setModalActive] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const handleInvalidToken = () => {
        localStorage.clear();
        setLoading(true);
        setLoggedIn(false);
        setLists([]);
        setModal({ type: 'sign-in', handleSignIn: handleSignIn });
    };

    const updateLists = (newLists) => {
        let parsedLists = Object.values(newLists);
        setLists(parsedLists);
        localStorage.setItem('lists', JSON.stringify(parsedLists));
    };

    const fetchUserData = async (token) => {
        try {
            const response = await fetch(`${API_URL}/users/${token}`);
            if (response.ok) {
                const data = await response.json();
                if (data.status === 'error') {
                    handleInvalidToken();
                    return;
                } else if (data.status === 'success') {
                    updateLists(data.lists);
                    setStatus('connected');
                }
            } else {
                handleInvalidToken();
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setStatus('offline');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if ('orientation' in window.screen && window.screen.orientation.lock) {
          window.screen.orientation.lock('portrait-primary').catch(err => {
            console.error('Orientation lock failed: ', err);
          });
        } else {
          console.warn('Screen orientation lock is not supported by this browser.');
        }
      }, []);

    useEffect(() => {
        const userToken = localStorage.getItem('user_token');
        if (userToken) {
            setLoggedIn(true);

            const storedLists = JSON.parse(localStorage.getItem('lists'));
            if (storedLists) {
                setLists(storedLists);
            }
            setLoading(false);
            if (isOnline) {
                setStatus('connected');
                fetchUserData(userToken);
            }
        } else {
            setLoggedIn(false);
            setStatus('connected');
            console.log('userToken not found');
            setModal({ type: 'sign-in', handleSignIn: handleSignIn });
        }
    }, []);

    useEffect(() => {
        const currentPath = location.pathname.substring(1);
        if (currentPath) {
            if (lists.some(list => list.list_uid === currentPath)) {
                setActiveListUid(currentPath);
            } else {
                setActiveListUid(null);
            }
        } else {
            const storedUid = localStorage.getItem('activeListUid');
            if (storedUid && lists.some(list => list.list_uid === storedUid)) {
                setActiveListUid(storedUid);
                navigate(`/${storedUid}`, { replace: true });
            } else {
                setActiveListUid(null);
            }
        }
    }, [location, lists, navigate]);

    useEffect(() => {
        const handleOnline = () => {
            setIsOnline(true);
            setStatus('connected');
        };
        const handleOffline = () => {
            setIsOnline(false);
            setStatus('offline');
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    useEffect(() => {
        let intervalId;
        const userToken = localStorage.getItem('user_token');

        if (isOnline && userToken) {
            intervalId = setInterval(() => {
                fetchUserData(userToken);
            }, 4000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isOnline, loggedIn]);

    const handleGoToList = (uid) => {
        setActiveListUid(uid);
        localStorage.setItem('activeListUid', uid);
        navigate(`/${uid}`, { replace: true });
    };

    const setModal = (data) => {
        setModalData(data);
        setModalActive(true);
    };

    const closeModal = () => {
        setTimeout(() => {
            setModalActive(false);
            setModalData(null);
        }, 200);
    };

    const handleNewList = () => {
        if (loading) return;

        if (status === 'offline') {
            setModal({ type: 'offline-notice' });
        } else {
            setModal({ type: 'add-list', handleGoToList: handleGoToList });
        }
    };

    const handleManageAccount = () => {
        if (loading) return;
        setModal({ type: 'manage-account', handleSignOut: handleSignOut });
    };

    const handleSignIn = (token, lists) => {
        localStorage.setItem('user_token', token);
        updateLists(lists);
        setLoggedIn(true);
        setLoading(false);
        closeModal();
    };

    const handleSignOut = () => {
        localStorage.clear();
        console.log('userToken removed');
        setLoggedIn(false);
        setLoading(true);
        setModal({ type: 'sign-in', handleSignIn: handleSignIn });
    };

    return (
        <>
            {modalActive && (
                <Modal API_URL={API_URL} updateLists={updateLists} modalData={modalData} setModal={setModal} closeModal={closeModal} />
            )}
            <div className="app">
                <nav className="sidebar">
                    <div className="sidebar-left">
                        <div className='sidebar-left-top'>
                            <div className='logo'>
                                <Logo />
                            </div>
                            {status === 'offline' && (
                                <div className='status --error'>
                                    <SVG.NetworkError />
                                </div>
                            )}
                            {status === 'connecting' && (
                                <div className='status --connecting'>
                                    <SVG.Spinner />
                                </div>
                            )}
                        </div>
                        <button className='user' onClick={handleManageAccount}>
                            <SVG.User />
                        </button>
                    </div>
                    <div className='lists'>
                        <div className='header'>
                            <span>My Lists</span>
                            <button onClick={handleNewList}><div className='icon'><SVG.Plus /></div></button>
                        </div>
                        {loading ? (
                            <div className='lists-menu-skeleton'>
                                <div className='item'>
                                    <div className='icon'></div>
                                    <div className='text'></div>
                                </div>
                                <div className='item'>
                                    <div className='icon'></div>
                                    <div className='text'></div>
                                </div>
                                <div className='item'>
                                    <div className='icon'></div>
                                    <div className='text'></div>
                                </div>
                            </div>
                        ) : (
                            <div className='lists-menu'>
                                {lists.length === 0 ? (
                                    <>
                                        <div className='no-lists-message'>You don't have any lists yet. Add or Create one to get started!</div>
                                        <div className='button-group --mob-no-grow --center'>
                                            <Button.Button className='--primary' onClick={handleNewList} ><span>Add a New List</span></Button.Button>
                                        </div>
                                    </>
                                ) : (
                                    lists.map((list) => (
                                        <ListMenuItem
                                            key={list.list_uid}
                                            list={list}
                                            isActive={list.list_uid === activeListUid}
                                            onClick={() => handleGoToList(list.list_uid)}
                                        />
                                    ))
                                )}
                            </div>
                        )}
                    </div>
                </nav>
                <Routes>
                    <Route path="/" element={
                        <div className={`mobile-menu ${status !== 'connected' ? ' --has-status' : ''}`}>
                            <div className='header'>
                                {(status === 'offline' || status === 'connecting') && (
                                    <div className='header-status'>
                                        {status === 'offline' && (
                                            <>
                                                <div className='icon --error'><SVG.NetworkError /></div>
                                                <span>Offline</span>
                                            </>
                                        )}
                                        {status === 'connecting' && (
                                            <>
                                                <div className='icon --connecting'><SVG.Spinner /></div>
                                                <span>Connecting</span>
                                            </>
                                        )}
                                    </div>
                                )}
                                <div className='header-container'>
                                    <button onClick={handleManageAccount}><div className='icon'><SVG.User /></div></button>
                                    <span>My Lists</span>
                                    <button onClick={handleNewList}><div className='icon'><SVG.Plus /></div></button>
                                </div>
                            </div>

                            {loading ? (
                                <div className='lists-menu-skeleton'>
                                    <div className='item'>
                                        <div className='icon'></div>
                                        <div className='text'></div>
                                    </div>
                                    <div className='item'>
                                        <div className='icon'></div>
                                        <div className='text'></div>
                                    </div>
                                    <div className='item'>
                                        <div className='icon'></div>
                                        <div className='text'></div>
                                    </div>
                                </div>
                            ) : (
                                <div className='lists-menu'>
                                    {lists.length === 0 ? (
                                        <>
                                            <div className='no-lists-message'>You don't have any lists yet. <br />Add or Create one to get started!</div>
                                            <div className='button-group --mob-no-grow --center'>
                                                <Button.Button className='--primary' onClick={handleNewList} ><span>Add a New List</span></Button.Button>
                                            </div>
                                        </>
                                    ) : (
                                        lists.map((list) => (
                                            <ListMenuItem
                                                key={list.list_uid}
                                                list={list}
                                                isActive={list.list_uid === activeListUid}
                                                onClick={() => handleGoToList(list.list_uid)}
                                            />
                                        ))
                                    )}
                                </div>
                            )}
                        </div>
                    } />
                    {!loading && (
                        <Route path="/:uid" element={
                            <List API_URL={API_URL} updateLists={updateLists} setLists={setLists} status={status} lists={lists} setModal={setModal} closeModal={closeModal} />
                        } />
                    )}
                </Routes>
            </div>
        </>
    );
}

export default App;
