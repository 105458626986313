import { useState, useEffect } from 'react';
import * as SVG from '../../SVGs';

function Text({ children, className = '', loading = false, locked = false, ...rest }) {
    const [isLoading, setIsLoading] = useState(loading);
    const [isLocked, setIsLocked] = useState(locked);

    useEffect(() => {
        setIsLoading(loading);
    }, [loading]);

    useEffect(() => {
        setIsLocked(locked);
    }, [locked]);

    const buttonClasses = `button-text ${className} ${isLoading ? '--loading' : ''}`.trim();

    return (
        <button 
            className={buttonClasses} 
            disabled={isLoading || isLocked} 
            {...rest}
        >
            <div className='button-content'>
                {children}
            </div>
            {isLoading && (
                <div className='button-loader'>
                    <SVG.Spinner />
                </div>
            )}
        </button>
    );
}

export default Text;