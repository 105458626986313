import React, { useState, useEffect } from 'react';
import * as SVG from '../../SVGs';
import * as Button from '../Buttons';
import * as Inputs from '../Forms/Inputs';
import Spacer from '../Spacer';

function ModalSignInCode({ API_URL, modalData, setModal, closeModal }) {
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState('');
    const [continueLocked, setContinueLocked] = useState(true);
    const [errorText, setErrorText] = useState('');

    //use effect to check if the otp is 6 digits, if it is, unlock the continue button
    useEffect(() => {
        if(otp.length === 6){
            setContinueLocked(false);
        } else {
            setContinueLocked(true);
        }
    }, [otp]);


    const handleClose = () => {
        document.querySelector('.modal').classList.remove('--active');
        document.querySelector('.modal-overlay').classList.remove('--active');
        setTimeout(() => {
            closeModal();
        }, 300);
    }

    const handleSignIn = (e) => {
        modalData.handleSignIn(otp);
    }

    const handleGoBack = (e) => {
        setModal({type: 'sign-in'});
    }

    const handleOTPChange = (newOtp) => {
        setOtp(newOtp);
        console.log('OTP:', newOtp);
        setErrorText('');
    };

    let bodyText = (<p className=''>Check your emails, we've sent you a <strong>6-Digit Sign-In Code</strong> which you'll need to enter below.</p>);

    if(modalData.referer === 'device'){
        bodyText = (<p className=''>Using a device where you're already signed in, go to your <strong>Account Settings</strong>, and then <strong>Generate a Sign-In Code</strong>.</p>)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        //if the continue button is locked, don't submit the form
        if(continueLocked){
            return;
        }

        setLoading(true);
        setContinueLocked(true);
    
        const formData = new FormData(document.getElementById('sign-in-code-form'));
        console.log(formData);
    
        try {
            const response = await fetch(`${API_URL}/user_tokens`, {
                method: 'POST',
                body: formData,
            });
    
            if (response.ok) {
                const data = await response.json();
                if (data.status === 'success') {
                    modalData.handleSignIn(data.token, data.lists);
                } else if (data.status === 'error'){
                    setErrorText(data.message);
                    setLoading(false);
                }
            }
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {bodyText}
            <Spacer line={false} size='small' />
            <form id='sign-in-code-form' className={`form`} onSubmit={handleSubmit}>
                <input type='hidden' name='one_time_code' value={otp} />
            <Inputs.OTPInput
                title="Enter a Sign-In Code"
                slug="sign-in-code"
                value={otp}
                onChange={handleOTPChange}
                locked={loading}
                helpText="Enter the 6-Digit Sign-In Code from your other device."
                errorText = {errorText}
            />
            </form>
            <Spacer line={false} size='small' />
            <div className='button-group'>
                <Button.Button type='button' className={`--primary`} loading={loading} locked={continueLocked} onClick={handleSubmit}><span>Continue</span><SVG.ArrowRight /></Button.Button>
                <Button.Button type='button' className={``} locked={loading} onClick={handleGoBack}><span>Go Back</span></Button.Button>
            </div>
        </>
    );
}

export default ModalSignInCode;
