import React, { useState, useEffect } from 'react';
import * as SVG from '../../SVGs';
import * as Button from '../Buttons';
import * as Inputs from '../Forms/Inputs';
import Spacer from '../Spacer';

function ModalAddList({ API_URL, modalData, setModal, closeModal }) {
    const [loading, setLoading] = useState(false);
    const [joinLocked, setJoinLocked] = useState(true);
    const [shareCode, setShareCode] = useState('');
    const [shareCodeError, setShareCodeError] = useState('');

    useEffect(() => {
        if(shareCode.length === 6){
            setJoinLocked(false);
        } else {
            setJoinLocked(true);
        }
    }, [shareCode]);

    const handleCreateList = () => {
        setModal({ type: 'new-list', handleGoToList: modalData.handleGoToList});
    };

    const handleShareCodeChange = (newShareCode) => {
        setShareCode(newShareCode);
        console.log('OTP:', newShareCode);
    };

    const handleJoinList = async (event) => {
        event.preventDefault();

        if(joinLocked){
            return;
        }

        setLoading(true);
        setJoinLocked(true);

        try {
            const response = await fetch(`${API_URL}/list_join_code/${shareCode}?token=${localStorage.getItem('user_token')}`, {
                method: 'GET',
            });

            if (response.ok) {
                const data = await response.json();
                if (data.status === 'success') {
                    //setLoading(false);
                    setModal({ type: 'join-list', handleGoToList: modalData.handleGoToList, shareCode: shareCode, listName: data.list_name, listColour: data.list_colour, listIcon: data.list_icon});
                } else {
                    setLoading(false);
                    setJoinLocked(false);
                    setShareCodeError(data.message);
                }
            }
        } catch (error) {
            console.error('Fetch error:', error);

        }
    }

    return (
        <>
            <p className='--center'>Create a new list or join an existing one</p>
            <div className='button-group --fw'>
                <Button.Button className='--primary' onClick={handleCreateList}><span>Create a New List</span></Button.Button>
            </div>
            <Spacer line={true} text='Or' />
            <form onSubmit={handleJoinList} className='form '>
            <Inputs.OTPInput
                className='--center'
                title="Enter a Share Code"
                slug="share-code"
                value={shareCode}
                onChange={handleShareCodeChange}
                helpText="Enter the 6-Digit Share Code for the list you'd like to join."
                errorText={shareCodeError}
            />
                <div className='form-group'>
                    <div className='button-group --center'>
                    <Button.Button type='submit' locked={joinLocked}  loading={loading}><span>Join List</span></Button.Button>
                    </div>
                </div>
            </form>
        </>
    );
}

export default ModalAddList;
