import React, { useState } from 'react';
import * as SVG from '../../SVGs';
import * as Button from '../Buttons';
import Spacer from '../Spacer';

function ModalShareList({ API_URL, modalData, setModal, closeModal }) {
    const [loading, setLoading] = useState(false);
    const [locked , setLocked] = useState(false);
    const [shareCode, setShareCode] = useState(null);

    const handleCreateCode = async () => {
        setLocked(true);
        setLoading(true);
        try {
            const response = await fetch(`${API_URL}/lists/${modalData['uid']}/share_code?token=${localStorage.getItem('user_token')}`, {
                method: 'GET',
            });
    
            if (response.ok) {
                const data = await response.json();
                if (data.status === 'success') {
                    setShareCode(data.code);
                    setLoading(false);
                }
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    };

    return (
        <>
            <p className=''>Use the button below to generate a <strong>Share Code</strong>. Give this to another person to let them join this List.</p>
            <div className='button-group --fw'>
                <Button.Button className={`--primary --${modalData['listColour']}`} onClick={handleCreateCode} locked={locked} loading={loading}><span>Create Share Code</span></Button.Button>
            </div>
            <Spacer size='extra-small' />
            <div className='share-code'>
                {shareCode && shareCode.split('').map((char, index) => (
                    <span key={index}>{char}</span>
                ))}
            </div>
        </>
    );
}

export default ModalShareList;
