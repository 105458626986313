import React, { useState, useEffect } from 'react';
import * as SVG from '../../SVGs';
import * as Button from '../Buttons';
import * as Inputs from '../Forms/Inputs';
import Spacer from '../Spacer';

function ColourOption(props) {
    const isActive = props.selectedColour === props.colour;

    const handleClick = () => {
        props.handleSelectColour(props.colour);
    }
    return (
        <button type='button' className={`option --${props.colour} ${isActive ? '--active' : ''}`} onClick={handleClick}>
            <div className='icon'></div>
        </button>
    );
}

function IconOption(props) {
    const isActive = props.selectedIcon === props.icon;
    const IconComponent = SVG[props.icon];

    const handleClick = () => {
        props.handleSelectIcon(props.icon);
    }
    return (
        <button type='button' className={`option --${props.icon} ${isActive ? '--active' : ''}`} onClick={handleClick}>
            <div className='icon'>{IconComponent ? React.createElement(IconComponent) : null}</div>
        </button>
    );
}


function ModalJoinList({ API_URL, updateLists, modalData, setModal, closeModal }){
    const [selectedColour, setSelectedColour] = useState(modalData['listColour']);
    const [selectedIcon, setSelectedIcon] = useState(modalData['listIcon']);
    const [listNameError, setListNameError] = useState(null);
    const [userNameError, setUserNameError] = useState(null);
    const [formLocked, setFormLocked] = useState(false);
    const [buttonLocked, setButtonLocked] = useState(false);
    const [loading, setLoading] = useState(false);
    const colourList = [
        'sky-blue',
        'lilac',
        'pink',
        'red',
        'orange',
        'olive',
        'green',
        'teal',
        'deep-blue'
    ];

    const iconList = [
        'List',
        'Bookmark',
        'Gift',
        'Cake',
        'GraduationCap',
        'Book',
        'Briefcase',
        'Money',
        'Utensils',
        'Drink',
        'Home',
        'Plane',
        'Boat',
        'Car',
        'Basket',
        'CartShopping',
        'Box',
        'Paw',
        'Music',
        'User'
    ];
    

    const handleSelectColour = (colour) => {
        if(formLocked) return;
        setSelectedColour(colour);
    }

    const handleSelectIcon = (icon) => {
        if(formLocked) return;
        setSelectedIcon(icon);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setButtonLocked(true);
        setLoading(true);
        setFormLocked(true);

        const formData = new FormData(document.getElementById('join-list-form'));

        try {
            // Perform save logic here (e.g., update API call)
            const response = await fetch(`${API_URL}/user_list?token=${localStorage.getItem('user_token')}`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const data = await response.json();
                if (data.status === 'success') {
                    updateLists(data.lists);
                    modalData.handleGoToList(data.new_list_uid);
                    closeModal();
                }else if(data.status === 'error'){
                    if(data.user_name_error){
                        setUserNameError(data.user_name_error);
                    }
                    if(data.list_name_error){
                        setListNameError(data.list_name_error);
                    }
                    setButtonLocked(false);
                    setLoading(false);
                    setFormLocked(false);
                }
            }else{
                setButtonLocked(false);
                setLoading(false);
                setFormLocked(false);
            }
        } catch (error) {
            console.error('Fetch error:', error);
            setButtonLocked(false);
            setLoading(false);
            setFormLocked(false);
        }
    };

    return (
        <>
        <form id='join-list-form' onSubmit={handleSubmit} className={`form --${selectedColour}`}>
            <input type='hidden' name='share_code' value={modalData['shareCode']} />
            <input type='hidden' name='list_colour' value={selectedColour} />
            <input type='hidden' name='list_icon' value={selectedIcon} />
            <Inputs.Text title='List Name' slug='list_name' helpText="What should this list be called?" errorText={listNameError} locked={formLocked} value={modalData['listName']} />
            <div className='form-group'>
                <label>Colour</label>
                <div className='colour-selector'>
                    {colourList.map(colour => {
                        return <ColourOption key={colour} colour={colour} selectedColour={selectedColour} handleSelectColour={handleSelectColour} />
                    })}
                </div>
            </div>
            <div className='form-group'>
                <label>Icon</label>
                <div className='icon-selector'>
                    {iconList.map(icon => {
                        return <IconOption key={icon} icon={icon} selectedIcon={selectedIcon} handleSelectIcon={handleSelectIcon} />
                    })}
                </div>
            </div>
            <Spacer size='small' line={true} />
            <Inputs.Text title='Your Name' slug='user_name' helpText="What should your name be on this list? This is how you'll be seen by other people that use this list." errorText={userNameError} locked={formLocked} />
            <Spacer size='extra-small' />
            <div className='form-group'>
                <div className='button-group --fw --stack'>
                <Button.Button type='submit' className={`--primary --${selectedColour}`} locked={buttonLocked} loading={loading}><span>Join List</span></Button.Button>
                </div>
            </div>
        </form>
        </>
    );
}

export default ModalJoinList;